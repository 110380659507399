@charset "utf-8";
@import "./assets/scss/_variables";
@import "./assets/scss/_mixins";

// Reset
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body,
html {
  // font-size: 125%; // For "db_heaventmed" : 20px
  line-height: 1;

  $breakpoint-tablet: 768px;
  @media (min-width: $breakpoint-tablet) {
    font-size: 175%; // For "db_heaventmed" : 28px
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  display: inline-block;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
:after,
:before {
  box-sizing: border-box;
}

// Fonts --------------------------------- //
@font-face {
  font-family: "kt_smarnspecialregular";
  src: url("./assets/fonts/kt_smarnspecial-webfont.eot");
  src: url("./assets/fonts/kt_smarnspecial-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/kt_smarnspecial-webfont.woff2") format("woff2"),
    url("./assets/fonts/kt_smarnspecial-webfont.woff") format("woff"),
    url("./assets/fonts/kt_smarnspecial-webfont.ttf") format("truetype"),
    url("./assets/fonts/kt_smarnspecial-webfont.svg#kt_smarnspecialregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventbold";
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventmed";
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventregular";
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.svg#db_heaventregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventlight";
  src: url("./assets/fonts/db_heavent_li_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_li_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_li_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_li_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_li_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_li_v3.2-webfont.svg#db_heaventlight")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventthin";
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

// Main ----------------------------------//
.App {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  font-family: "db_heaventbold";

  // Monthly
  .App-monthly {
    // height: 100vh;
    .App-background {
      background-position: center;
      // overflow-y: scroll;
      height: auto;
    }
    .App-header {
      width: 100%;
    }

    .App-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .App-bar {
      margin-top: 12%;
    }

    .App-status {
      margin-top: 4%;
    }

    .App-text-extra {
      margin: 4% 0 0;
    }
  }

  // Task
  .App-task {
    .App-background {
      background-position: center;
      height: auto;
    }
    .App-header {
      width: 100%;
      position: relative;
      .App-content {
        width: 100%;
        position: absolute;
        margin: auto;
        bottom: 15%;
      }
    }
    .App-path {
      margin: 15% 0;
      height: 1000px;
      position: relative;
      @include mobile {
        height: 1000 * $vw-mb;
      }

      .App-path-bg {
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translate(6%, 0);

        .App-path-list {
          z-index: 20;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          li {
            list-style: none;
            margin: 0;
            padding: 0;
            transform: translate(-30%, -30%);

            position: absolute;
            top: 0;
            left: 0;

            // Start: Edit for last pin
            &:last-child {
              transform: translate(-38%, -38%);
              display: flex;
              align-items: center;
              justify-content: center;

              width: 260px;
              height: 260px;
              @include mobile {
                width: 260 * $vw-mb;
                height: 260 * $vw-mb;
              }

              .App-path-pin {
                width: 160px;
                height: 160px;
                // opacity: 0.2;
                @include mobile {
                  width: 160 * $vw-mb;
                  height: 160 * $vw-mb;
                }
                &._reached,
                &._current {
                  width: 160px;
                  height: 160px;
                  // opacity: 0.2;
                  @include mobile {
                    width: 160 * $vw-mb;
                    height: 160 * $vw-mb;
                  }
                }
              }
            }
            // End: Edit for last pin

            .App-path-pin {
              background-repeat: no-repeat;
              background-size: 100% auto;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 160px;
              height: 160px;
              // opacity: 0.2;
              @include mobile {
                width: 160 * $vw-mb;
                height: 160 * $vw-mb;
              }

              span {
                font-family: "kt_smarnspecialregular";
              }
            }
            .last-pin {
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              top: 0;
              bottom: 0;
              z-index: -1;
              transform: translate(-20%, 0%);
              &.App-path-pin {
                width: 260px;
                height: 260px;
                // opacity: 0.2;
                @include mobile {
                  width: 260 * $vw-mb;
                  height: 260 * $vw-mb;
                }
              }
            }

            &:nth-of-type(1) {
              top: -20px;
              left: 410px;
              @include mobile {
                top: -20 * $vw-mb;
                left: 410 * $vw-mb;
              }
            }
            &:nth-of-type(2) {
              top: -17px;
              left: 300px;
              @include mobile {
                top: -17 * $vw-mb;
                left: 305 * $vw-mb;
              }
            }
            &:nth-of-type(3) {
              top: -12px;
              left: 190px;
              @include mobile {
                top: -12 * $vw-mb;
                left: 190 * $vw-mb;
              }
            }
            &:nth-of-type(4) {
              top: 10px;
              left: 90px;
              @include mobile {
                top: 10 * $vw-mb;
                left: 90 * $vw-mb;
              }
            }
            &:nth-of-type(5) {
              top: 78px;
              left: 40px;
              @include mobile {
                top: 78 * $vw-mb;
                left: 40 * $vw-mb;
              }
            }
            &:nth-of-type(6) {
              top: 160px;
              left: 45px;
              @include mobile {
                top: 160 * $vw-mb;
                left: 45 * $vw-mb;
              }
            }
            &:nth-of-type(7) {
              top: 204px;
              left: 114px;
              @include mobile {
                top: 204 * $vw-mb;
                left: 114 * $vw-mb;
              }
            }
            &:nth-of-type(8) {
              top: 210px;
              left: 204px;
              @include mobile {
                top: 210 * $vw-mb;
                left: 204 * $vw-mb;
              }
            }
            &:nth-of-type(9) {
              top: 216px;
              left: 298px;
              @include mobile {
                top: 216 * $vw-mb;
                left: 298 * $vw-mb;
              }
            }
            &:nth-of-type(10) {
              top: 246px;
              left: 384px;
              @include mobile {
                top: 246 * $vw-mb;
                left: 384 * $vw-mb;
              }
            }
            &:nth-of-type(11) {
              top: 320px;
              left: 444px;
              @include mobile {
                top: 320 * $vw-mb;
                left: 444 * $vw-mb;
              }
            }
            &:nth-of-type(12) {
              top: 405px;
              left: 448px;
              @include mobile {
                top: 405 * $vw-mb;
                left: 448 * $vw-mb;
              }
            }
            &:nth-of-type(13) {
              top: 458px;
              left: 376px;
              @include mobile {
                top: 458 * $vw-mb;
                left: 376 * $vw-mb;
              }
            }
            &:nth-of-type(14) {
              top: 445px;
              left: 290px;
              @include mobile {
                top: 445 * $vw-mb;
                left: 290 * $vw-mb;
              }
            }
            &:nth-of-type(15) {
              top: 400px;
              left: 215px;
              @include mobile {
                top: 400 * $vw-mb;
                left: 215 * $vw-mb;
              }
            }
            &:nth-of-type(16) {
              top: 350px;
              left: 145px;
              @include mobile {
                top: 350 * $vw-mb;
                left: 145 * $vw-mb;
              }
            }
            &:nth-of-type(17) {
              top: 320px;
              left: 60px;
              @include mobile {
                top: 320 * $vw-mb;
                left: 60 * $vw-mb;
              }
            }
            &:nth-of-type(18) {
              top: 370px;
              left: 0px;
              @include mobile {
                top: 370 * $vw-mb;
                left: 0 * $vw-mb;
              }
            }
            &:nth-of-type(19) {
              top: 450px;
              left: -20px;
              @include mobile {
                top: 450 * $vw-mb;
                left: -20 * $vw-mb;
              }
            }
            &:nth-of-type(20) {
              top: 530px;
              left: -20px;
              @include mobile {
                top: 540 * $vw-mb;
                left: -10 * $vw-mb;
              }
            }
            &:nth-of-type(21) {
              top: 608px;
              left: 74px;
              @include mobile {
                top: 608 * $vw-mb;
                left: 74 * $vw-mb;
              }
            }
            &:nth-of-type(22) {
              top: 602px;
              left: 174px;
              @include mobile {
                top: 602 * $vw-mb;
                left: 174 * $vw-mb;
              }
            }
            &:nth-of-type(23) {
              top: 582px;
              left: 264px;
              @include mobile {
                top: 582 * $vw-mb;
                left: 264 * $vw-mb;
              }
            }
            &:nth-of-type(24) {
              top: 562px;
              left: 354px;
              @include mobile {
                top: 562 * $vw-mb;
                left: 354 * $vw-mb;
              }
            }
            &:nth-of-type(25) {
              top: 533px;
              left: 454px;
              @include mobile {
                top: 533 * $vw-mb;
                left: 454 * $vw-mb;
              }
            }
            &:nth-of-type(26) {
              top: 552px;
              left: 550px;
              @include mobile {
                top: 552 * $vw-mb;
                left: 550 * $vw-mb;
              }
            }
            &:nth-of-type(27) {
              top: 642px;
              left: 596px;
              @include mobile {
                top: 652 * $vw-mb;
                left: 596 * $vw-mb;
              }
            }
            &:nth-of-type(28) {
              top: 748px;
              left: 566px;
              @include mobile {
                top: 748 * $vw-mb;
                left: 566 * $vw-mb;
              }
            }
            &:nth-of-type(29) {
              top: 794px;
              left: 475px;
              @include mobile {
                top: 794 * $vw-mb;
                left: 475 * $vw-mb;
              }
            }
            &:nth-of-type(30) {
              top: 786px;
              left: 383px;
              @include mobile {
                top: 786 * $vw-mb;
                left: 383 * $vw-mb;
              }
            }
            &:nth-of-type(31) {
              top: 759px;
              left: 294px;
              @include mobile {
                top: 759 * $vw-mb;
                left: 294 * $vw-mb;
              }
            }
            &:nth-of-type(32) {
              top: 734px;
              left: 214px;
              @include mobile {
                top: 734 * $vw-mb;
                left: 214 * $vw-mb;
              }
            }
            &:nth-of-type(33) {
              top: 734px;
              left: 124px;
              @include mobile {
                top: 734 * $vw-mb;
                left: 124 * $vw-mb;
              }
            }
            &:nth-of-type(34) {
              top: 810px;
              left: 64px;
              @include mobile {
                top: 810 * $vw-mb;
                left: 64 * $vw-mb;
              }
            }
            &:nth-of-type(35) {
              top: 898px;
              left: 64px;
              @include mobile {
                top: 898 * $vw-mb;
                left: 64 * $vw-mb;
              }
            }
            &:nth-of-type(36) {
              top: 944px;
              left: 130px;
              @include mobile {
                top: 944 * $vw-mb;
                left: 130 * $vw-mb;
              }
            }
            &:nth-of-type(37) {
              top: 954px;
              left: 216px;
              @include mobile {
                top: 954 * $vw-mb;
                left: 216 * $vw-mb;
              }
            }
            &:nth-of-type(38) {
              top: 950px;
              left: 306px;
              @include mobile {
                top: 950 * $vw-mb;
                left: 306 * $vw-mb;
              }
            }
            &:nth-of-type(39) {
              top: 944px;
              left: 404px;
              @include mobile {
                top: 944 * $vw-mb;
                left: 404 * $vw-mb;
              }
            }
            &:nth-of-type(40) {
              top: 948px;
              left: 518px;
              @include mobile {
                top: 948 * $vw-mb;
                left: 518 * $vw-mb;
              }
            }
          }
        }
      }
    }
  }

  .App-leaderboard {
    .App-background {
      background-position: 0 top;
    }
    .App-header {
      width: 100%;
    }
    .App-content {
      margin-bottom: 4%;
      .App-content-text {
        &._12 {
          margin-bottom: 4%;
        }
        &._3 {
          margin-bottom: 2%;
        }
      }
    }
    .App-table {
      margin-bottom: 8%;
      .App-table-border {
        overflow: hidden;
        margin-bottom: 2%;
      }
      .App-table-top {
        font-family: "db_heaventregular";
      }
      table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 4px;
        overflow: hidden;
        tr {
          th {
            &:first-child {
              border-left: none;
            }
            &:nth-of-type(1) {
              width: 15%;
            }
            &:nth-of-type(2) {
              width: 30%;
            }
            &:nth-of-type(3) {
              width: 20%;
            }
            &:nth-of-type(4) {
              width: 35%;
            }
          }
          td {
            &:nth-of-type(2) {
              text-align: left;
            }
          }
        }
        tr:first-child {
          th {
            border-top: none;
          }
        }
        tr:last-child {
          td {
            border-bottom: none;
          }
        }
        th,
        td {
          vertical-align: middle;
          padding: 2%;
          font-size: 1.2rem;
          border: 1px solid;
          &:last-child {
            border-right: none;
          }
          &:first-child {
            border-left: none;
          }
        }
      }
    }
  }

  .App-Team {
    .App-background {
      background-position: 0 0;
      background-size: 100%;
      padding: 0;
    }
    .App-header {
      img {
        margin-top: 3%;
      }
    }
    @media screen and (max-width: 374px) {
      .App-header {
        width: 60%;
      }
    }

    .App-content {
      margin: 0 0 6.5%;
    }
    .App-bar {
      width: 88%;
      .App-target-box {
        position: absolute;
        margin: auto;
        transform: translateX(-48%);
        z-index: 2;
        top: 0;
        bottom: 0;
      }
    }
  }
}
.App-background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // height: 100%;
  width: 100%;
  padding: 0 5%;
  overflow: hidden;
  // min-height: 100vh;
}
.App-header {
  margin: 0 auto;
  text-align: center;
  width: 70%;
  img {
    width: 100%;
  }
}
.App-content {
  margin: 2% 0 0;
  p {
    line-height: 1;
  }
}

// Loading & Error & Main ------------------------------//
.App-loading,
.App-error,
.App-main {
  display: flex;
  width: 100vw;
  height: 100vh;
  p {
    font-size: 30px;
    flex: 1;
    align-self: center;
    padding: 0 20px;
  }
}

.Table-loading {
  display: flex;
  width: 100%;
  height: 50%;
  p {
    flex: 1;
    align-self: center;
    padding: 4% 20px;
  }
}

// Text Extra ----------------------------//
.App-text-extra {
  margin: 2% 0 0;
}

// Barcode -----------------------------//
.App-bar {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  margin: 4% 0 auto -3%;
  display: grid;
  .App-bar-border {
    width: 100%;
    // height: intrinsic;
    position: relative;
    z-index: 1;
  }
  .App-bar-stick {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: left;
    z-index: 0;
    .round-stick {
      border-radius: 100px;
      //   border: calc(2px + 2px) solid transparent;
      height: 100%;
      position: absolute;
      overflow: hidden;
      width: 100%;
      background-color: #fff;
      display: inline-flex;
    }
    .bar-group {
      width: 100%;
      display: inline-flex;
      .bar-stick {
        position: relative;
        display: inline-block;
        height: 100%;
        flex: none;
        &._green {
          background-color: #1bfc28;
          opacity: 0.8;
        }
        &._yellow {
          background-color: #fdfd01;
          // background-color: red;
          opacity: 0.8;
        }
      }
    }
  }
  .App-target-box {
    position: absolute;
    // right: 0;
    margin: auto;
    transform: translateX(-51.5%);
    z-index: 2;
    top: 0;
    bottom: 0;
    // opacity: 0.2;
    img {
      width: 100%;
    }
  }
}

// Barcode V.2 -----------------------------//
.App-bar-V2 {
    .App-banner {
      img {
        width: 100%;
      }
      .noimg {
        width: 100%;
        height: 200px;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #a2a2a2;
        text-transform: uppercase;
      }
    }
    .App-content {
      p {
        line-height: 1.2;
      }
      .texth {
        // font-size: 2rem;
        font-family: 'db_heaventmed';
        &.multiple-targets {
          font-size: 2rem;
        }
        &.single-target {
          font-size: 2.2rem;
        }
      }
      span {
        font-family: 'db_heaventmed';
        color: #595E73;
        font-size: 1.8rem;

        &.text2 {
          color: #199F98;
          font-size: 2rem;
        }
      }
    }
    .App-total {
      display: flex;
      padding: 0 20px;
      p:first-child {
        flex-grow: 2;
        text-align: left;
        padding-left: 14px;
      }
      p {
        flex-grow: 1;
        text-align: right;
        padding-right: 14px;
        .hilight {
          color: #199F98;
        }
      }
      .texth {
        font-size: 2rem;
        font-family: 'db_heaventmed';
      }
    }
    .App-bar-status {
      display: flex;
      padding: 2% 5% 0;
      justify-content: space-between;
      p {
        font-family: 'db_heaventmed';
        color: #595E73;
        font-size: 2rem;
        align-self: baseline;
        span {
          font-family: 'db_heaventbold';
          color: #199F98;
          font-size: 3rem;
        }
      }
    }
    .App-bar-section {
      &.single-target {
        padding: 2% 5%;
      }
      &.multiple-targets {
        padding: 4% 5% 9%;
      }
      .App-bar {
        // padding: 5%;
        margin: 0;
        .App-bar-border {
          width: 100%;
          height: 10px;
          background-color: #D6D8E3;
          border-radius: 20px;
          p {
            font-family: 'db_heaventmed';
            color: #000;
            font-size: 1.4rem;
            position: absolute;
            top: 20px;
          }
        }
        .App-target-box {
          width: 20px;
          height: 20px;
          // border-radius: 50px;
          p {
            font-family: 'db_heaventmed';
            font-size: 1.4rem;
            position: absolute;
            top: 25px;
            left: 0;
            right: 0;
          }
          .target-reached {
            background-color: #199F98;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            position: relative;
            &::after {
              content: '';
              width: 30px;
              height: 30px;
              border-radius: 100px;
              background-color: #199F98;
              opacity: .25;
              position: absolute;
              top: 0;
              bottom: 0;
              left: -5px;
              right: 0;
              margin: auto;
            }
            p {
              color: #199F98;
            }
          }
          .target-not-reached {
            background-color: #D6D8E3;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            p {
              color: #000;
            }
          }
        }
        .App-bar-stick {
          height: 10px;
          z-index: 2;
          .round-stick {
            background-color: transparent;
          }
          // .bar-group {
            .bar-stick {
              &._green {
                background-color: #199F98;
                position: absolute;
                opacity: 1;
                height: 10px;
              }
            }
          // }
        }
      }
    }
    .App-status {
      span {
        font-family: 'db_heaventmed';
        color: #595E73;
        font-size: 1.4rem;
      }
    }
}

.App-status {
  display: flex;
  justify-content: space-around;
  padding: 4% 0;
  .status-content {
    font-family: "db_heaventregular";
    display: flex;
    // width: 50%;
    align-items: center;
    img {
      width: 1.6rem;
      max-width: 47px;
    }
    .status-text {
      align-items: flex-end;
    }
  }
}

.App-monlty-box {
  display: flex;
  margin: 0 -3% 2%;
  .box {
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: 10px 1%;
    border-radius: 6px;
    padding: 2%;
  }
}

// MonthlyShort -------------------------//
.App-monthlyshort {
  .App-header {
    width: 100%;
  }
}

// MonthlyShort V.2 -------------------------//
.App-monthlyshort-V2 {
  .App-banner {
    img {
      width: 100%;
    }
    .noimg {
      width: 100%;
      height: 200px;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a2a2a2;
      text-transform: uppercase;
    }
  }
  .App-header {
    width: 100%;
  }

  .App-status {
    font-size: 1.4rem;
    font-family: 'db_heaventregular';
    color: #595E73;
  }

  .App-content {
    padding: 10px 20px;
    .App-content-box {
      border-radius: 6px;
      overflow: hidden;
    }
    .texth {
      font-size: 2.2rem;
      font-family: 'db_heaventmed';
    }
    p {
      font-size: 1.4rem;
      font-family: 'db_heaventregular';
    }
    .normal-gray {
      color: #595E73;
    }
    .first-content {
      background-color: #F9EAED;
      padding: 10px;
      .hilight {
        color: #F07A3C;
        font-size: 1.6rem;
        font-family: 'db_heaventbold';
      }
    }
    .second-content {
      background-color: #FCF5F6;
      padding: 10px;
      .hilight {
        color: #199F98;
        font-size: 2.2rem;
        font-family: 'db_heaventbold';
      }
      .hilight-unit {
        font-size: 2rem;
        font-family: 'db_heaventmed';
      }
    }
  }
}

// WeeklyShort -------------------------//
.App-weekly {
  .App-header {
    width: 100%;
  }

  .App-bar-text {
    display: flex;
    justify-content: space-around;
    .App-bar-text-item {
      flex-grow: 1;

      &._1 {
        max-width: 35%;
      }
      
      &._2 {
        max-width: 50%;
      }

      &._3 {
        max-width: 33%;
      }

      &._4 {
        max-width: 25%;
      }

      &._5 {
        max-width: 20%;
      }
      
      &:first-child {
        .App-doughnutChart {
          &::before {
            content: none;
          }
        }
      }
    }
  }

  .App-content {
    margin: 2% 0 4%;
  }

  .text4reached, .text4 {
    white-space: nowrap;
    &._4 {
      line-height: 0.8;
    }
  }

  .text5 {
    white-space: nowrap;
  }
  
  // #App-bar-result {
  //   .App-bar-text {
  //     .text4reached, .text4 {
  //       &::before {
  //         white-space: inherit;
  //       }
  //     }
  //     .text5 {
  //       &::before {
  //         white-space: inherit;
  //       }
  //     }
  //   }
  // }

  .text5 {
    margin-top: 10%;
  }

  .App-doughnutChart {
    position: relative;
    border-radius: 100%;
    margin: auto;
    width: 100%;
    height: 100%;

    &::before {
      content: "";
      width: 100%;
      height: 10%;
      position: absolute;
      bottom: 45%;
      left: -50%;
    }

    &._period2 {
      &::before {
        width: 150%;
        left: -120%;
      }
    }

    &._period3 {
      &::before {
        width: 125%;
        left: -85%;
      }
    }
    
    &._period4 {
      &::before {
        width: 120%;
        left: -65%;
      }
    }

    &._period5 {
      &::before {
        width: 115%;
        left: -70%;
      }
    }
    .App-imgChart {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 74%;
      height: 74%;
      background: #fff;
      border-radius: 100%;
      z-index: 2;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img { 
        // width: 100%;
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: 0;
        // margin: auto;
        // bottom: 0; 
        height: 100%;
      }
      .App-checkMark {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: 5% auto;
        bottom: 0; 
        width: 74%;
        height: 74%;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
      }
    }
    canvas {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: -5px auto auto;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      z-index: 1;
    }
  }
  
}

// WeeklyShort V.2 -------------------------//
.App-weekly-V2 {
  .App-banner {
    img {
      width: 100%;
    }
    .noimg {
      width: 100%;
      height: 200px;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a2a2a2;
      text-transform: uppercase;
    }
  }
  .App-header {
    width: 100%;
  }

  .App-bar-text {
    display: block;
    justify-content: space-around;
    .App-bar-text-item {
      display: flex;
      position: relative;
      text-align: left;

      &._1 {
        z-index: 4;
      }
      &._2 {
        z-index: 3;
      }
      &._3 {
        z-index: 2;
      }
      &._4 {
        z-index: 1;
      }
      &._5 {
        z-index: 0;
      }
      
      &:first-child {
        .App-dotted {
          &::before {
            height: 15px;
            bottom: -10px;
            border-radius: 50px 50px 0 0;
            top:-58%;
          }
        }
      }

      .App-bar-text-box {
        display: flex;
        align-items: center;
        > span {
          display: inline-block;
          font-size: 1.8rem;
          font-family: 'db_heaventregular';
          &.text3, &.text3reached {
            color: #199F98;
          }
          &.text3reached {
            font-family: 'db_heaventbold';
          }
        }
      }
    }
  }

  .App-content {
    margin: 2% 20px 4%;
    p {
      font-size: 1.8rem;
      font-family: 'db_heaventregular';
      span {
        color: #199F98;
        &.custom {
          color: #000;
        }
      }
    }
  }

  .App-status {
    font-size: 1.4rem;
    font-family: 'db_heaventregular';
    color: #595E73;
  }

  .App-dotted {
    position: relative;
    border-radius: 100%;
    margin: 0;
    width: 100%;
    height: 100%;
    display: inline-block;

    .dottedItem {
      width: 26px;
      height: 26px;
      border-radius: 50px;
      position: absolute;
      left: 10px;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      background-color: #D6D8E3;
      border: 2px solid #D6D8E3;
      display: flex;
      &._current {
        &::after {
          content: '';
          width: 36px;
          height: 36px;
          border-radius: 100px;
          background-color: #199F98;
          opacity: .25;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -7px;
          right: 0;
          margin: auto;
          z-index: -1;
        }
      }
    }

    &._reached {
      &::before {
        background-color: #199F98;
        border: 2px solid #199F98;  
      }
      .dottedItem {
        background-color: #199F98;
        border: 2px solid #199F98;
      }
    }

    &::before {
      content: "";
      width: 10%;
      height: 100%;
      position: absolute;
      top: -50%;
      background-color: #D6D8E3;
      left: 10px;
      right: 0;
      margin: auto;
    }

    &._period2 {
      &::before {
        height: 110%;
        top: -55%;
      }
    }

    &._period3 {
      &::before {
        height: 110%;
        top: -55%;
      }
    }
    
    &._period4 {
      &::before {
        height: 110%;
        top: -55%;
      }
    }

    &._period5 {
      &::before {
        height: 110%;
        top: -55%;
      }
    }

    .App-checkMark {
      // width: 100%;
      // position: absolute;
      // left: 0;
      // right: 0;
      // top: 0;
      margin: auto;
      bottom: 0; 
      width: 74%;
      height: 74%;
      line-height: 1.6;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      z-index: 3;
      text-align: center;
      .checkmark {
        display: inline-block;
        transform: rotate(45deg);
        height: 14px;
        width: 6px;
        // margin-left: 60%; 
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
      }
    }
    .App-imgChart {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 74%;
      height: 74%;
      background: #fff;
      border-radius: 100%;
      z-index: 2;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img { 
        // width: 100%;
        // position: absolute;
        // left: 0;
        // right: 0;
        // top: 0;
        // margin: auto;
        // bottom: 0; 
        height: 100%;
      }
    }
    .dotted {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: -5px auto auto;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      z-index: 1;
    }
  }
  
}

// Task V.2 -------------------------//
.App-task-V2 {
  .App-banner {
    img {
      width: 100%;
    }
    .noimg {
      width: 100%;
      height: 200px;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a2a2a2;
      text-transform: uppercase;
    }
  }
  .App-header {
    width: 100%;
  }
  .App-content {
    margin: 2% 20px 4%;
    p {
      font-size: 2rem;
      font-family: 'db_heaventregular';
      span {
        color: #199F98;
        &.custom {
          color: #000;
        }
      }
    }
    .doughnutBox {
      position: relative;
      margin: auto;
      .doughnutDetailBox {
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
          color: #595E73;
          font-size: 2.2rem;
          font-family: 'db_heaventmed';
          line-height: .8;
          span {
            color: #199F98;
            font-size: 3.2rem;
            line-height: .8;
          }
          &:first-child {
            font-size: 1.8rem;
            line-height: .8;
          }
          &:last-child {
            font-size: 2.6rem;
            line-height: .8;
          }
        }
      }
    }
  }
  .App-status {
    font-size: 1.4rem;
    font-family: 'db_heaventregular';
    color: #595E73;
  }
}

// LeaderBoard V.2 -------------------------//
.App-leaderboard-V2 {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  .App-banner {
    img {
      width: 100%;
    }
    .noimg {
      width: 100%;
      height: 200px;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a2a2a2;
      text-transform: uppercase;
    }
  }
  .App-header {
    width: 100%;
  }
  .App-select-period {
    padding: 10px;
    select {
      width: 100%;
      height: auto;
      border: 1px solid #eee;
      padding: 2%;
      border-radius: 4px;
      background-color: #fff;
      font-size: 1.4rem;
      font-family: 'db_heaventmed';
      color: #000;
    }
  }
  .App-content-rank {
    margin-bottom: 2%;
    .text-orange {
      font-size: 2.4rem;
      font-family: 'db_heaventmed';
      line-height: .6;
    }
  }
  .text-normal {
    color: #000;
  }
  .text-orange {
    color: #EF8045;
  }
  .App-content {
    margin: 2% 20px 4%;
    p {
      font-size: 1.8rem;
      font-family: 'db_heaventmed';
    }
  }
  .App-table {
    padding: 0 4%;
    table {
      width: 100%;
      th, td {
        width: 50%;
        padding: 1% 0;
        &:first-child {
          width: 20%;
        }
        &:last-child {
          width: 29%;
        }
      }
      th {
        font-family: 'db_heaventmed';
        font-size: 1.4rem;
      }
      td {
        font-family: 'db_heaventregular';
        font-size: 1.2rem;
      }
      tr {
        border-bottom: 1px solid #eee;
      }
      &.App-table-self {
        th {
          color: #199F98;
          padding: 2% 0;
        }
      }
    }
  }
  .App-status {
    font-size: 1.4rem;
    font-family: 'db_heaventregular';
    color: #595E73;
  }
}

// Monthly V.2 -------------------------//
.App-monthly-V2 {
  .App-banner {
    img {
      width: 100%;
    }
    .noimg {
      width: 100%;
      height: 200px;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a2a2a2;
      text-transform: uppercase;
    }
  }
  .App-header {
    width: 100%;
  }
  .App-content {
    margin: 2% 20px 4%;
    p {
      font-size: 1.8rem;
      font-family: 'db_heaventregular';
      span {
        &.hilight {
          color: #199F98;
          font-family: 'db_heaventbold';
        }
        &.hilight-orange {
          color: #EF7B3C;
          font-family: 'db_heaventbold';
          font-size: 2.4rem;
        }
        &.normal {
          color: #000;
        }
        &.normal-gray {
          color: #595E73;
        }
      }
    }
    .doughnutBox {
      position: relative;
      margin: auto;
      .doughnutDetailBox {
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        bottom: 0;
        margin: auto;
        background-color: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
          color: #595E73;
          font-size: 1.6rem;
          font-family: 'db_heaventmed';
          line-height: .8;
          span {
            color: #199F98;
            font-size: 3rem;
            line-height: .8;
          }
          &:first-child {
            font-size: 1.6rem;
            line-height: .8;
          }
          &:last-child {
            font-size: 1.6rem;
            line-height: .8;
          }
        }
      }
    }
    .header-target {
      font-family: 'db_heaventmed';
    }

    .App-bar-status {
      display: flex;
      padding: 2% 5% 0;
      justify-content: space-between;
      p {
        font-family: 'db_heaventmed';
        color: #595E73;
        font-size: 2rem;
        align-self: baseline;
        span {
          font-family: 'db_heaventbold';
          color: #199F98;
          font-size: 3rem;
        }
      }
    }
    
    .App-bar-section {
      padding: 6% 0 12%;
      border-bottom: 1px solid #FAEAED;
      .App-bar {
        // padding: 5%;
        margin: 0;
        .App-bar-border {
          width: 100%;
          height: 10px;
          background-color: #D6D8E3;
          border-radius: 20px;
          p {
            font-family: 'db_heaventmed';
            color: #000;
            font-size: 1.15rem;
            position: absolute;
            top: 22px;
          }
        }
        .App-target-box {
          width: 20px;
          height: 20px;
          // border-radius: 50px;
          p {
            font-family: 'db_heaventmed';
            font-size: 1.4rem;
            position: absolute;
            top: 25px;
            left: 0;
            right: 0;
          }
          .target-reached {
            background-color: #199F98;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            position: relative;
            box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.25);
            -webkit-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.25);
            &::after {
              content: '';
              width: 30px;
              height: 30px;
              border-radius: 100px;
              background-color: #199F98;
              opacity: .25;
              position: absolute;
              top: 0;
              bottom: 0;
              left: -5px;
              right: 0;
              margin: auto;
            }
            p {
              color: #199F98;
            }
          }
          .target-not-reached {
            background-color: #D6D8E3;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            p {
              color: #000;
            }
          }
        }
        .App-bar-stick {
          height: 10px;
          z-index: 2;
          .round-stick {
            background-color: transparent;
          }
          // .bar-group {
            .bar-stick {
              &._green {
                background-color: #199F98;
                position: absolute;
                opacity: 1;
                height: 10px;
              }
            }
          // }
        }
      }
    }

  }
  .App-detail {
    display: flex;
    ._left {    
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .header {
        font-family: 'db_heaventbold';
        font-size: 1.8rem;
        margin-bottom: 4px;
        text-align: left;
      }
      p {
        font-size: 1.6rem;
        align-self: baseline;
        &.tag-month {
          width: 42%;
          text-align: left;
          margin-right: 8px;
        }
        &.tag-result {
          text-align: left;
        }
      }
      .hilight {
        // margin-left: 8px;
        font-size: 2rem;
        font-family: 'db_heaventbold';
      }
      br { display: none;}
      .row-unit { margin: 0; }
    }
    ._right {
      width: 40%;
    }
    .tag-youcan {
      display: none;
    }
    .box {
      display: flex;
      padding: 2px 0 4px;
    }
  }
  .App-total {
    display: flex;
    p:first-child {
      flex-grow: 2;
      text-align: left;
      padding-left: 14px;
    }
    p {
      flex-grow: 1;
      text-align: right;
      padding-right: 14px;
    }
  }
  .App-status {
    font-size: 1.4rem;
    font-family: 'db_heaventregular';
    color: #595E73;
  }
  .App-text-extra {
    margin: 2% 3% 0;
    .textx {
      font-size: 1.8rem;
      font-family: 'db_heaventmed';
        span {
          &.hilight {
            color: #199F98;
          }
          &.hilight-orange {
            color: #EF7B3C;
            font-family: 'db_heaventbold';
            font-size: 2.4rem;
          }
          &.normal {
            color: #000;
          }
          &.normal-gray {
            color: #595E73;
          }
        }
    }
  }
  .App-monlty-box {
    margin: 0 3% 0;
    p {
      font-family: 'db_heaventmed';
      color: #595E73;
      align-self: baseline;
      font-size: 1.4rem;
      span {
        font-size: 1.6rem;
        &.hilight {
          color: #199F98;
          font-family: 'db_heaventbold';
        }
      }
    }
    .box {
      background-color: #FCF5F6;
      padding: 0;
      border-radius: 10px;
      margin-bottom: 0;
    }
    .tag-month {
      font-size: 1.6rem;
      background-color: #FAEAED;
      font-family: 'db_heaventbold';
      border-radius: 10px 10px 0 0;
      padding: 4% 0;
      color: #000;
    }
    .tag-youcan {
      padding: 10% 0 0;
    }
    .tag-result {
      padding: 4% 0 10%;
    }
  }
}

// Stamp -----------------------------//
.App-stamp {
  overflow: auto;
  margin: 0;
  padding: 0;
  background-position: 0 0;
  background-size: cover;
  // background: fixed;
  height: 100vh;
  .App-banner {
    display: flex;
    img {
      width: 100%;
    }
    .noimg {
      width: 100%;
      height: 200px;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a2a2a2;
      text-transform: uppercase;
    }
  }
  .App-header {
    width: 100%;
  }
  .App-content {
    padding: 0 20px;
    .App-stamp-title {
      font-size: 1.8rem;
      font-family: 'db_heaventmed';
      color: #646464;
      padding: 5% 0 0;
      margin-bottom: 5%;
      h3 {
        font-size: 1.8rem;
      }
      span {
        color:#000;
      }
    }
    .App-stamp-list {
      margin-bottom: 7%;
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
      }
      li {
        position: relative;
        margin: 4px;
        width: 17%;
        height: 17%;
      }
      .stamp-item {
        // position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background-color: #d9d9d9;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5%;
        p {
          font-size: 1.6rem;
          position: absolute;
          z-index: 1;
        }
        img {
          width: 100%;
        }
      }
      // .stamp-item {
      //   flex: 1 1 18%;
      //   background-color: #a2a2a2;
      //   width: 18%;
      //   height: calc(375px/6);
      //   border-radius: 100px;
      // }
      &.no-6 {
        li {
          width: 30%;
          height: 30%;
        }
        .stamp-item {
          p {
            display: none;
          }
        }
      }
    }
    .App-pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4%;
      .num-pagination {
        font-size: 1.2rem;
        color: #424242;
      }
      .btn-pagination {
        width: 1.4rem;
        height: 1.4rem;
        background-color: transparent;
        border-radius: 100px;
        border: 1px solid #424242;
        img {
          width: 50%;
        }
        &.btn-disable {
          pointer-events: none;
          opacity: .25;
        }
      }
    }
    .App-scanbutton {
      margin-bottom: 5%;
      button {
        background: transparent;
        border: none;
        img {
          width: 100%;
        }
      }
      a.App-scanbutton-icon {
        width: 100%;
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .App-reward {
      border-top: 2px solid #eaeaea;
      padding: 5% 0;
      text-align: left;
      .App-reward-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        .reward-accordion {
          width: 3%;
        }
        h4 {
          font-size: 1.6rem;
        }
      }
      .App-reward-list {
        overflow: auto;
        ul {
          li {
            &:first-child {
              .reward-item { 
                border: none;
              }
            }
          }
        }
        .reward-item {
          display: flex;
          padding: 2% 0;
          border-top: 1px solid #e2e2e2;
          .reward-item-img {
            min-width: 22%;
            max-width: 22%;
            img {
              width: 100%;
            }
          }
          .reward-item-figcaption {
            padding: 0 0 0 3%;
            // p {
            //   color: #424242;
            // }
            .reward-item-burnpoint {
              display: flex;
              align-items: center;
              font-size: 1.4rem;
              p {
                font-family: 'db_heaventregular';
                background-color: #e2e2e2;
                padding: 2px 6px;
                border-radius: 100px;
                width: fit-content;
              }
              span {
                font-family: 'db_heaventbold';
                &.reward-item-earned {
                  color: #23cd2d;
                  margin-left: 5px;
                }
              }
            }
            .reward-item-name {
              font-size: 1.4rem;
            }
            .reward-item-detail {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-family: 'db_heaventregular';
            }

          }
        }
      }
    }
  }
}


.LinesEllipsis {
  color: #424242;
  font-family: 'db_heaventregular';
  .LinesEllipsis-ellipsis {
    font-family: 'db_heaventregular';
  }
}

.ReactModal__Overlay {
  z-index: 10;
}
._modal {
  .modal-content {
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    position: absolute;
    height: fit-content;
    width: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    .modal-content-close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      img {
        width: 80%;
      }
    }
    .modal-content-body {
      text-align: center;
      .modal-content-img {
        width: 50%;
        margin: 0 auto 3%;
        img {
          width: 100%;
        }
      }
      .modal-content-burnpoint {
        p {
          font-family: 'db_heaventregular';
          background-color: #e2e2e2;
          padding: 2px 6px;
          border-radius: 100px;
          width: fit-content;
          text-align: center;
          margin: 0 auto 3%;
          span {
            font-family: 'db_heaventbold';
          }
        }
      }
      .modal-content-name {
        font-size: 1.8rem;
        font-family: 'db_heaventbold';
        margin: 0 auto 3%;
      }
      .modal-content-detail {
        font-family: 'db_heaventregular';
        overflow: auto;
        .modal-content-detail-box {
          padding: 1px 0;
          text-align: initial;
          h1 {
            font-size: 2em;
          }
          h2 {
            font-size: 1.875em;
          }
          h3 {
            font-size: 1.5em;
          }
          h4 {
            font-size: 1.25em;
          }
          h5 {
            font-size: 1.125em;
          }
          h6 {
            font-size: 1em;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .App-weekly-V2 {
    .App-content {
      p {
        font-size: 1.5rem;
      }
    }
    .App-bar-text {
      .App-bar-text-item {
        .App-bar-text-box {
          > span {
            font-size: 1.5rem;
          }
        }
      }
    }
    .App-dotted {
      .App-checkMark {
        line-height: 1.6;
      }
    }
  }
}

@media (max-width: 320px) {
  .App-weekly-V2 {
    .App-content {
      p {
        font-size: 1.4rem;
      }
    }
    .App-bar-text {
      .App-bar-text-item {
        &:first-child {
          .App-dotted {
            &::before {
              bottom: 0;
            }
          }
        }
        .App-bar-text-box {
          > span {
            font-size: 1.4rem;
          }
        }
      }
    }
    .App-dotted {
      .App-checkMark {
        line-height: 1;
      }
    }
  }

  .App-leaderboard-V2 {
    .App-content {
      p {
        font-size: 1.6rem;
      }
    }
  }

  .App-monthly-V2 {
    .App-detail {
      ._left {
        width: 62%;
        .header {
          font-size: 1.5rem;
        }
        p {
          font-size: 1.3rem;
          &.tag-month {
            width: 40%;
            margin-right: 4px;
          }
        }
        .hilight {
          font-size: 1.8rem;
        }
      }
      ._right {
        width: 38%;
      }
    }
    .App-content {
      .header-target {
        font-size: 1.8rem;
      }
      p {
        font-size: 1.6rem;
      }
      .App-bar-section {
        .App-bar {
          .App-bar-border {
            p {
              font-size: .9rem;
              top: 22px;
            }
          }
          .App-target-box {
            .target-reached {
              p {
                font-size: 1.1rem;
              }
            }
            .target-not-reached {
              p {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }
  }

  .App-stamp  {
    .App-content {
      .App-stamp-list  {
        ul {
          justify-content: center;
        }
        li {
          width: 16%;
          height: 16%;
        }
      }
    }
  }
}